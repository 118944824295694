* {
    box-sizing: border-box;
}

body {
    /*     margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 */
    background: url("./images/wall_reduced.jpg") fixed;
    min-height: 100vh;
    height: 100%;
    background-size: cover !important;
    padding-bottom: 1vh;
    font-size: 1rem;
}

.footnote {
    font-size: 0.8rem;
}
/* 
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
 */

 a {
     color: black;
     text-shadow: 3px 3px 5px rgba(65, 59, 59, 0.5);
     text-decoration: none;
 }

 a:hover {
    color: rgb(77, 67, 67);
    text-decoration: none;
    text-shadow: 3px 3px 5px rgba(65, 59, 59, 0.5);
 }

 /* boxes with rounded corners */
.box-text {
     background-color: white;
     border-radius: 5px;
}

 /* page headings */
.headline {
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.2;
    padding: 1.2rem 0;
    background-color: transparent;
    text-shadow: 5px 5px 10px rgb(65, 59, 59);
    text-align: center;
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Custom styling of accordions 
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.accordion-button {
    font-size: 1.5rem;
    font-weight: bold;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: white;
    color: black;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Custom styling graphs
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.graphs__yaxis {
    color:black;
    font-weight: normal;
    font-size: small;
    
}
.graphs__xaxis {
    color: black;
    font-weight: normal;
    font-size: small;
    margin-bottom: 10px;
}