.dot {
    height: 15px;
    width: 15px;
    background-color: rgba(99, 95, 95, 0.8);;
    border-radius: 50%;
    display: inline-block;

}

.with_pointer {
    cursor: "pointer";
}

.legend {
    color: rgba(99, 95, 95, 0.8);
}

.legend_selected {
    font-weight: 500;
    text-shadow: 3px 3px 5px rgba(65, 59, 59, 0.5);
    color: black;
}