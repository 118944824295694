.overlay__background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.overlay__container {
    /* background-color: white; */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    padding: 30px;
    width: fit-content;
    height: fit-content;
}

.overlay__container > h1 {
    color: rgb(250, 245, 245);
}